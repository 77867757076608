












import { Component, Vue } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'

@Observer
@Component
export default class StakingButton extends Vue {
  walletStore = walletStore
}
